import React from "react";

const OriginalImage = React.memo(({ title, image, placeholder, onLoad }) => {
  console.log('🎨 OriginalImage 렌더링 시작:', new Date().toISOString());
  
  const handleImageLoad = () => {
    console.log('✨ 원본 이미지 로드 완료:', new Date().toISOString());
    onLoad?.();
  };

  return (
    <div className="flex flex-col items-center">
      <p className="text-sm font-medium text-gray-700 mb-2">{title}</p>
      <div className="w-full bg-gray-50 rounded-lg overflow-hidden flex items-center justify-center">
        {image ? (
          <img
            src={image}
            alt={title}
            className="w-full h-auto object-contain"
            loading="eager"
            decoding="async"
            onLoad={handleImageLoad}
          />
        ) : (
          <p className="text-gray-500">{placeholder}</p>
        )}
      </div>
    </div>
  );
});

export default OriginalImage; 