import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { useState } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import Navigation from '../components/Navigation';
import ResultModal from '../components/ResultModal';
import { useAuth } from '../contexts/AuthContext';

// Chart.js 등록
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

// 더미 데이터 수정
const DUMMY_DATA = {
  genderDistribution: {
    labels: ['남성', '여성'],
    data: [65, 35],
  },
  ageDistribution: {
    labels: ['~19', '20~29', '30~39', '40~49', '50~'],
    data: [15, 30, 25, 20, 10],
  },
  emotionDistribution: {
    labels: ['행복', '슬픔', '화남', '놀람', '중립'],
    data: [40, 15, 10, 15, 20],
  },
  apiRequests: [
    {
      id: 1,
      endpoint: '/age-estimation',
      status: 'success',
      responseTime: 0.8,
      createdAt: '2024-01-10T14:30:00Z',
      results: [
        {
          gender: '남성',
          age: 28,
          emotion: '행복'
        }, 
        {
          gender: '여성',
          age: 34,
          emotion: '중립'
        }
      ]
    },
    {
      id: 2,
      endpoint: '/age-estimation',
      status: 'success',
      responseTime: 0.6,
      createdAt: '2024-01-10T14:35:00Z',
      results: [
        {
          gender: '여성',
          age: 34,
          emotion: '중립'
        }
      ]
    }
  ],
  timeSeriesData: {
    hourly: {
      labels: Array.from({length: 24}, (_, i) => `${23-i}시간 전`).reverse(),
      requests: Array.from({length: 24}, () => Math.floor(Math.random() * 100 + 50)),
      responseTimes: Array.from({length: 24}, () => (Math.random() * 0.5 + 0.3).toFixed(2))
    },
    daily: {
      labels: Array.from({length: 7}, (_, i) => `${6-i}일 전`).reverse(),
      requests: Array.from({length: 7}, () => Math.floor(Math.random() * 1000 + 500)),
      responseTimes: Array.from({length: 7}, () => (Math.random() * 0.5 + 0.3).toFixed(2))
    },
    monthly: {
      labels: Array.from({length: 30}, (_, i) => `${29-i}일 전`).reverse(),
      requests: Array.from({length: 30}, () => Math.floor(Math.random() * 1000 + 500)),
      responseTimes: Array.from({length: 30}, () => (Math.random() * 0.5 + 0.3).toFixed(2))
    }
  }
};

const Dashboard = () => {
  const { logout } = useAuth();
  const [selectedPeriod, setSelectedPeriod] = useState('week');
  const [selectedResults, setSelectedResults] = useState(null);

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  const genderData = {
    labels: DUMMY_DATA.genderDistribution.labels,
    datasets: [
      {
        data: DUMMY_DATA.genderDistribution.data,
        backgroundColor: ['rgba(54, 162, 235, 0.8)', 'rgba(255, 99, 132, 0.8)'],
      },
    ],
  };

  const ageData = {
    labels: DUMMY_DATA.ageDistribution.labels,
    datasets: [
      {
        label: '연령대별 분포',
        data: DUMMY_DATA.ageDistribution.data,
        backgroundColor: 'rgba(75, 192, 192, 0.8)',
      },
    ],
  };

  const emotionData = {
    labels: DUMMY_DATA.emotionDistribution.labels,
    datasets: [
      {
        data: DUMMY_DATA.emotionDistribution.data,
        backgroundColor: [
          'rgba(255, 206, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(255, 99, 132, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(153, 102, 255, 0.8)',
        ],
      },
    ],
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('ko-KR', {
      style: 'currency',
      currency: 'KRW'
    }).format(amount);
  };

  const formatDate = (dateString) => {
    return format(new Date(dateString), 'yyyy년 MM월 dd일', { locale: ko });
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'success':
        return 'bg-green-100 text-green-800';
      case 'error':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  // 시계열 데이터를 위한 옵션
  const timeSeriesOptions = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: '시간대별 API 요청 추이'
      }
    },
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  // 응답 시간 차트를 위한 옵션
  const responseTimeOptions = {
    ...timeSeriesOptions,
    plugins: {
      ...timeSeriesOptions.plugins,
      title: {
        display: true,
        text: '평균 응답 시간 추이'
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `${value}s`
        }
      }
    }
  };

  // 시계열 데이터 준비
  const periodData = DUMMY_DATA.timeSeriesData[selectedPeriod === 'day' ? 'hourly' : 
                                             selectedPeriod === 'week' ? 'daily' : 'monthly'];

  const requestsData = {
    labels: periodData.labels,
    datasets: [
      {
        label: 'API 요청 수',
        data: periodData.requests,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        tension: 0.1
      }
    ]
  };

  const responseTimeData = {
    labels: periodData.labels,
    datasets: [
      {
        label: '평균 응답 시간',
        data: periodData.responseTimes,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.1
      }
    ]
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Navigation />
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {/* 기간 선택 */}
        <div className="mb-8">
          <div className="flex justify-end">
            <select
              value={selectedPeriod}
              onChange={(e) => setSelectedPeriod(e.target.value)}
              className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option value="day">오늘</option>
              <option value="week">이번 주</option>
              <option value="month">이번 달</option>
            </select>
          </div>
        </div>

        {/* API 요청 추이 차트 */}
        <div className="mb-8 grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="bg-white p-6 rounded-lg shadow">
            <div className="h-80">
              <Line data={requestsData} options={timeSeriesOptions} />
            </div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow">
            <div className="h-80">
              <Line data={responseTimeData} options={responseTimeOptions} />
            </div>
          </div>
        </div>

        {/* 분석 결과 차트 */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          {/* 성별 분포 */}
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-lg font-semibold mb-4">성별 분포</h2>
            <div className="h-64">
              <Pie data={genderData} options={chartOptions} />
            </div>
          </div>

          {/* 연령대 분포 */}
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-lg font-semibold mb-4">연령대 분포</h2>
            <div className="h-64">
              <Bar data={ageData} options={chartOptions} />
            </div>
          </div>

          {/* 감정 분포 */}
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-lg font-semibold mb-4">감정 분포</h2>
            <div className="h-64">
              <Pie data={emotionData} options={chartOptions} />
            </div>
          </div>
        </div>

        {/* API 요청 기록 테이블 */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4">API 요청 기록</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    시간
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    엔드포인트
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    상태
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    응답시간
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    분석 결과
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {DUMMY_DATA.apiRequests.map((request) => (
                  <tr key={request.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(request.createdAt)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {request.endpoint}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeClass(request.status)}`}>
                        {request.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {request.responseTime.toFixed(2)}s
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button
                        onClick={() => setSelectedResults(request.results)}
                        className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        결과 확인
                        <span className="ml-1.5 text-xs text-indigo-500">
                          ({Array.isArray(request.results) ? request.results.length : 1}개)
                        </span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* 결과 모달 */}
        <ResultModal
          isOpen={selectedResults !== null}
          onClose={() => setSelectedResults(null)}
          results={Array.isArray(selectedResults) ? selectedResults : selectedResults ? [selectedResults] : []}
        />
      </main>
    </div>
  );
};

export default Dashboard; 