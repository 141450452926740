import Cookies from "js-cookie";
import React, { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext(null);

// 임시 인증 정보
const TEMP_CREDENTIALS = {
  username: "root",
  password: "root_password",
  email: "admin@benchley.com",
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // 저장된 사용자 정보 확인
    const savedUser = Cookies.get("user");
    if (savedUser) {
      setUser(JSON.parse(savedUser));
    }
    setLoading(false);
  }, []);

  const login = async (credentials) => {
    // 임시 인증 로직
    if (
      credentials.username === TEMP_CREDENTIALS.username &&
      credentials.password === TEMP_CREDENTIALS.password
    ) {
      const userData = {
        id: "1",
        username: credentials.username,
        is_admin: true,
      };

      setUser(userData);
      Cookies.set("user", JSON.stringify(userData));
      return userData;
    }

    throw new Error("아이디 또는 비밀번호가 잘못되었습니다.");
  };

  const logout = () => {
    setUser(null);
    Cookies.remove("user");
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
