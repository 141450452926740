export const exportToCSV = (data, filename) => {
  // CSV 헤더
  const headers = [
    '정산 기간',
    'API 요청 수',
    '정산 금액',
    '상태',
    '정산일',
  ];

  // 데이터를 CSV 형식으로 변환
  const csvData = data.map(row => [
    row.period,
    row.totalRequests,
    row.amount,
    row.isPaid ? '정산 완료' : '정산 예정',
    row.paidDate || '-'
  ]);

  // 헤더와 데이터 결합
  const csvContent = [
    headers,
    ...csvData
  ].map(row => row.join(',')).join('\n');

  // CSV 파일 생성 및 다운로드
  const blob = new Blob(['\uFEFF' + csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  
  link.setAttribute('href', url);
  link.setAttribute('download', `${filename}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}; 