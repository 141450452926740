import React, { useCallback, useEffect, useRef, useState } from "react";

const ImageContainer = React.memo(
  ({ title, image, loading, placeholder, metadata }) => {
    console.log("📦 ImageContainer 렌더링 시작:", new Date().toISOString());

    const imgRef = useRef(null);
    const [imageDimensions, setImageDimensions] = useState({
      width: 0,
      height: 0,
    });
    const [showBoxes, setShowBoxes] = useState(false);

    const updateDimensions = useCallback(() => {
      if (imgRef.current) {
        const { width, height } = imgRef.current.getBoundingClientRect();
        console.log("📐 이미지 크기 업데이트:", { width, height });
        setImageDimensions({ width, height });
      }
    }, []);

    const handleImageLoad = () => {
      console.log("🎯 처리된 이미지 로드 완료:", new Date().toISOString());
      // 이미지 로드 완료 후 크기 업데이트
      updateDimensions();
    };

    useEffect(() => {
      console.log("🔄 이미지 변경 감지:", !!image);
      if (image) {
        updateDimensions();
      }
    }, [image, updateDimensions]);

    useEffect(() => {
      console.log("📏 현재 이미지 크기:", imageDimensions);
      console.log("📍 메타데이터 존재:", !!metadata);
    }, [imageDimensions, metadata]);

    useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
    }, [updateDimensions]);

    if (!image) {
      return (
        <div className="flex flex-col items-center">
          <p className="text-sm font-medium text-gray-700 mb-2">{title}</p>
          <div className="w-full bg-gray-50 rounded-lg overflow-hidden flex items-center justify-center">
            <p className="text-gray-500">{placeholder}</p>
          </div>
        </div>
      );
    }

    return (
      <div className="flex flex-col items-center">
        <div className="flex items-center gap-2 mb-2">
          <p className="text-sm font-medium text-gray-700">{title}</p>
          {metadata?.face_boxes && (
            <button
              onClick={() => {
                setShowBoxes(!showBoxes);
                // 박스 토글 시 크기 재계산
                setTimeout(updateDimensions, 0);
              }}
              className={`px-2 py-1 text-xs rounded ${
                showBoxes
                  ? "bg-yellow-400 text-black"
                  : "bg-gray-200 text-gray-600"
              }`}
            >
              박스 {showBoxes ? "숨기기" : "표시"}
            </button>
          )}
        </div>

        <div className="relative">
          <img
            ref={imgRef}
            src={image}
            alt={title}
            className="max-w-full h-auto"
            loading="lazy"
            onLoad={handleImageLoad}
          />

          {showBoxes &&
            metadata?.face_boxes?.map((box, index) => {
              const boxStyle = {
                left: `${
                  (box.x1 / metadata.image_width) * imageDimensions.width
                }px`,
                top: `${
                  (box.y1 / metadata.image_height) * imageDimensions.height
                }px`,
                width: `${
                  ((box.x2 - box.x1) / metadata.image_width) *
                  imageDimensions.width
                }px`,
                height: `${
                  ((box.y2 - box.y1) / metadata.image_height) *
                  imageDimensions.height
                }px`,
              };
              console.log(`📦 박스 ${index} 스타일:`, boxStyle);

              return (
                <div
                  key={index}
                  className="absolute border-2 border-yellow-400"
                  style={boxStyle}
                >
                  <span className="absolute -top-6 left-1/2 transform -translate-x-1/2 bg-yellow-400 text-black px-2 py-1 rounded text-sm">
                    {box.estimated_age}살
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
);

export default ImageContainer;
