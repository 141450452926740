import { PhotoIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import ImageContainer from "../components/ImageContainer";
import OriginalImage from "../components/OriginalImage";
import Navigation from "../components/Navigation";

const DEMO_CASES = [
  {
    id: 'age-estimation',
    name: '연령 추정',
    description: '얼굴 이미지에서 연령을 추정합니다',
    endpoint: '/age-estimation'
  },
  {
    id: 'mbti-estimation',
    name: 'MBTI 추정',
    description: '얼굴 이미지에서 MBTI를 추정합니다',
    endpoint: '/mbti-estimation'
  },
  {
    id: 'bounty-estimation',
    name: '현상금 추정',
    description: '원피스 캐릭터의 현상금을 추정합니다',
    endpoint: '/bounty-estimation'
  }
];

const Demo = () => {
  const [image, setImage] = useState(null);
  const [processedResult, setProcessedResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [originalImageLoaded, setOriginalImageLoaded] = useState(false);
  const [selectedCase, setSelectedCase] = useState(DEMO_CASES[0].id);

  const processImage = useCallback(async (file) => {
    console.log("🚀 이미지 처리 시작:", new Date().toISOString());
    const formData = new FormData();
    formData.append("file", file);

    try {
      const selectedEndpoint = DEMO_CASES.find(c => c.id === selectedCase).endpoint;
      const apiUrl =
        process.env.NODE_ENV === "production"
          ? `/api${selectedEndpoint}`
          : `http://localhost:8000${selectedEndpoint}`;

      const response = await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("✅ API 응답 수신:", new Date().toISOString());
      setProcessedResult(response.data);
    } catch (err) {
      console.error("❌ API 에러:", err);
      setError("이미지 처리 중 오류가 발생했습니다.");
    } finally {
      setLoading(false);
    }
  }, [selectedCase]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        console.log("📁 파일 드롭됨:", file.size / 1024, "KB");

        setImage(null);
        setProcessedResult(null);
        setLoading(true);
        setError(null);

        setTimeout(() => {
          const imageUrl = URL.createObjectURL(file);
          setImage(imageUrl);
          console.log("🖼️ 원본 이미지 URL 생성");

          const optimizeImage = async () => {
            const optimizedImage = await new Promise((resolve) => {
              const img = new Image();
              img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                const maxSize = 1200;
                let width = img.width;
                let height = img.height;

                if (width > maxSize || height > maxSize) {
                  if (width > height) {
                    height = (height / width) * maxSize;
                    width = maxSize;
                  } else {
                    width = (width / height) * maxSize;
                    height = maxSize;
                  }
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(
                  (blob) => {
                    resolve(new File([blob], file.name, { type: "image/jpeg" }));
                  },
                  "image/jpeg",
                  0.8
                );
              };
              img.src = imageUrl;
            });

            processImage(optimizedImage);
          };

          optimizeImage();
        }, 100);
      }
    },
    [processImage]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
    maxFiles: 1,
    onDrop,
  });

  const handleOriginalImageLoad = useCallback(() => {
    setOriginalImageLoaded(true);
  }, []);

  const handleCaseChange = (e) => {
    setSelectedCase(e.target.value);
    // 케이스가 변경되면 이전 결과 초기화
    setImage(null);
    setProcessedResult(null);
    setError(null);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Navigation />
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        {/* 케이스 선택 섹션 */}
        <div className="mb-8">
          <div className="bg-white p-6 rounded-lg shadow">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
              <div className="mb-4 sm:mb-0">
                <h2 className="text-lg font-semibold text-gray-900">
                  데모 케이스 선택
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  테스트하고 싶은 AI 모델을 선택하세요
                </p>
              </div>
              <div className="w-full sm:w-72">
                <div className="relative">
                  <select
                    value={selectedCase}
                    onChange={handleCaseChange}
                    className="block w-full px-4 py-3 text-base border-2 border-gray-200 rounded-lg appearance-none bg-white hover:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-colors duration-200"
                  >
                    {DEMO_CASES.map(demoCase => (
                      <option key={demoCase.id} value={demoCase.id}>
                        {demoCase.name}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                    <svg 
                      className="w-5 h-5 text-gray-400" 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth="2" 
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
                <div className="mt-2 p-3 bg-gray-50 rounded-lg">
                  <p className="text-sm text-gray-600">
                    {DEMO_CASES.find(c => c.id === selectedCase)?.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 이미지 업로드 섹션 */}
        <div className="mb-8">
          <div
            {...getRootProps()}
            className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center cursor-pointer hover:border-gray-400 transition-colors max-w-2xl mx-auto"
          >
            <input {...getInputProps()} />
            <div className="space-y-4">
              <PhotoIcon className="mx-auto h-12 w-12 text-gray-400" />
              {isDragActive ? (
                <p className="text-gray-600">이미지를 여기에 놓으세요</p>
              ) : (
                <>
                  <p className="text-gray-600">
                    이미지를 드래그하거나 클릭하여 업로드하세요
                  </p>
                  <p className="text-sm text-gray-500">
                    PNG, JPG, JPEG (최대 10MB)
                  </p>
                </>
              )}
            </div>
          </div>
        </div>

        {/* 결과 비교 섹션 */}
        {(image || processedResult || loading) && (
          <div className="flex flex-col space-y-4">
            <h2 className="text-xl font-semibold text-gray-900 text-center">
              이미지 비교
            </h2>
            <div className="grid grid-cols-2 gap-4">
              <div className="col-start-1">
                <OriginalImage
                  title="원본 이미지"
                  image={image}
                  placeholder="원본 이미지가 여기에 표시됩니다"
                  onLoad={handleOriginalImageLoad}
                />
              </div>
              <div className="col-start-2">
                <ImageContainer
                  title="결과 이미지"
                  image={processedResult?.image}
                  loading={loading}
                  placeholder="처리된 이미지가 여기에 표시됩니다"
                  metadata={processedResult?.metadata}
                />
              </div>
            </div>
          </div>
        )}

        {error && (
          <div className="mt-4 p-4 bg-red-50 rounded-lg max-w-2xl mx-auto">
            <p className="text-red-600 text-center">{error}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Demo; 