import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import Navigation from '../components/Navigation';
import { exportToCSV } from '../utils/csvExport';

// 더미 데이터 (나중에 context나 props로 받을 수 있음)
const DUMMY_BILLING_DATA = {
  currentMonth: {
    totalRequests: 1250,
    amount: 375000,
    startDate: '2024-01-05',
    endDate: '2024-02-04',
    isPaid: false,
    dueDate: '2024-02-10'
  },
  recentMonths: [
    {
      period: '2023-12',
      totalRequests: 1100,
      amount: 330000,
      isPaid: true,
      paidDate: '2024-01-08'
    },
    {
      period: '2023-11',
      totalRequests: 980,
      amount: 294000,
      isPaid: true,
      paidDate: '2023-12-07'
    }
  ]
};

const Billing = () => {
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('ko-KR', {
      style: 'currency',
      currency: 'KRW'
    }).format(amount);
  };

  const formatDate = (dateString) => {
    return format(new Date(dateString), 'yyyy년 MM월 dd일', { locale: ko });
  };

  const handleDownloadCSV = () => {
    const currentDate = format(new Date(), 'yyyy-MM', { locale: ko });
    const filename = `정산내역_${currentDate}`;
    
    // 현재 달 정보를 포함한 데이터 생성
    const exportData = [
      {
        period: format(new Date(DUMMY_BILLING_DATA.currentMonth.startDate), 'yyyy-MM'),
        totalRequests: DUMMY_BILLING_DATA.currentMonth.totalRequests,
        amount: DUMMY_BILLING_DATA.currentMonth.amount,
        isPaid: DUMMY_BILLING_DATA.currentMonth.isPaid,
        paidDate: DUMMY_BILLING_DATA.currentMonth.isPaid ? DUMMY_BILLING_DATA.currentMonth.paidDate : null
      },
      ...DUMMY_BILLING_DATA.recentMonths
    ];
    
    exportToCSV(exportData, filename);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Navigation />
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {/* 정산 요약 섹션 */}
        <div className="mb-8">
          <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                이번 달 정산 정보
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                {formatDate(DUMMY_BILLING_DATA.currentMonth.startDate)} ~ {formatDate(DUMMY_BILLING_DATA.currentMonth.endDate)}
              </p>
            </div>
            <div className="px-4 py-5 sm:p-6">
              <div className="grid grid-cols-1 gap-5 sm:grid-cols-3">
                <div className="bg-gray-50 px-4 py-5 rounded-lg">
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    총 API 요청 수
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold text-gray-900">
                    {DUMMY_BILLING_DATA.currentMonth.totalRequests.toLocaleString()}회
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 rounded-lg">
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    예상 정산 금액
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold text-indigo-600">
                    {formatCurrency(DUMMY_BILLING_DATA.currentMonth.amount)}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 rounded-lg">
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    정산 예정일
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold text-gray-900">
                    {formatDate(DUMMY_BILLING_DATA.currentMonth.dueDate)}
                  </dd>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 최근 정산 내역 */}
        <div className="mb-8">
          <div className="bg-white shadow rounded-lg">
            <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                최근 정산 내역
              </h3>
              <button
                onClick={handleDownloadCSV}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <svg 
                  className="mr-2 h-4 w-4" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" 
                  />
                </svg>
                CSV 다운로드
              </button>
            </div>
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            정산 기간
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            API 요청 수
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            정산 금액
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            상태
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            정산일
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {DUMMY_BILLING_DATA.recentMonths.map((month) => (
                          <tr key={month.period}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {month.period}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {month.totalRequests.toLocaleString()}회
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {formatCurrency(month.amount)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                month.isPaid ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                              }`}>
                                {month.isPaid ? '정산 완료' : '정산 예정'}
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {month.isPaid ? formatDate(month.paidDate) : '-'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Billing; 